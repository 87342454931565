import React from "react"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { StaticQuery, graphql } from "gatsby"

import { Box, Column, Container, DunkText, H1 } from "components"

const TermsConditionsPage = () => (
  <StaticQuery
    query={graphql`
      {
        datoCmsTermsConditionsPage {
          title
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
          seoKeywords
          terms
        }
      }
    `}
    render={data => (
      <>
        <HelmetDatoCms seo={data.datoCmsTermsConditionsPage.seoMetaTags}>
          <meta
            name="keywords"
            content={data.datoCmsTermsConditionsPage.seoKeywords}
          />
        </HelmetDatoCms>
        <Box as="section" pt={[96, 120]} bg="background">
          <Container py={[5, 6]}>
            <Column left>
              <H1 as="h1">
                <DunkText lines={["Terms", "&", "Conditions"]} />
              </H1>
              {data.datoCmsTermsConditionsPage.terms && (
                <Box
                  as="article"
                  mt={[3, 4]}
                  fontSize={[4, 5]}
                  dangerouslySetInnerHTML={{
                    __html: data.datoCmsTermsConditionsPage.terms,
                  }}
                ></Box>
              )}
            </Column>
          </Container>
        </Box>
      </>
    )}
  />
)

export default TermsConditionsPage
